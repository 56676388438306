import React from 'react';
import Section from 'components/section';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <Section>Not found.</Section>
  </Layout>
);

export default NotFound;
